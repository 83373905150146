import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import md5 from 'js-md5';
import i18n from './i18n/i18n'
import '@/assets/css/index.css'
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false
import 'swiper/dist/css/swiper.css'
Vue.use(ElementUI);
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
