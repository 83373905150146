export default {
    '首页': '首页',
    '公司愿景': '公司愿景',
    '公司介绍': '公司介绍',
    '公司优势': '公司优势',
    '加入我们': '加入我们',
    '在线开户': '在线开户',
    '公司地址': '公司地址',
    '香港特别行政区上环区德辅道西103号乐基商业中心2601室': '香港特别行政区上环区德辅道西103号乐基商业中心2601室',
    '公司网站': '公司网站',
    '公司联系邮箱': '公司联系邮箱',
    '联系电话': '联系电话',
    '雲瑞資產管理有限公司': '雲瑞資產管理有限公司',
    '雲瑞證券有線公司': '雲瑞證券有線公司',
    '本网站信息有中英两种文本': '本网站信息有中英两种文本，如有冲突以英文版为准,中文版本仅供参考。',
    '取消': '取消',
    '确定': '确定'
}