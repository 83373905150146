export default {
    '首页': 'Home',
    '公司愿景': 'Our Vision',
    '公司介绍': 'Our Company',
    '公司优势': 'Our Advantage',
    '加入我们': 'Join Us',
    '在线开户': 'Account Opening',
    '公司地址': 'Address',
    '香港特别行政区上环区德辅道西103号乐基商业中心2601室': ' Rm 2601,Lucky Commercial Center,103 Des Voeux Rd West,Sheung Wan,Hong Kong ',
    '公司网站': ' Website ',
    '公司联系邮箱': ' Email ',
    '联系电话': 'Tel',
    '雲瑞資產管理有限公司': ' YUNRUI ASSET MANAGEMENT LIMITED',
    '雲瑞證券有線公司': 'YUNRUI SECURITIES LIMITED',
    '本网站信息有中英两种文本': 'This website information is made out in both English and Chinese versions. We hereby set English version as standard and Chinese version as a reference should any conflicts accrued.',
    '取消': 'Cancel',
    '确定': 'Confirm'
}