<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {

  }
</script>
<style lang="scss">
@media only screen and (max-width: 800px){
  .visible-h5{
    display: flex !important;
  }
  .visible-pc{
    display: none !important;
  }
}
</style>
